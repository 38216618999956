import React, { useState } from "react"
import HomeLayout from "../HomeLayout/HomeLayout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Flippy from "react-flippy/dist/Flippy"
import { FrontSide, BackSide } from "react-flippy/dist/FlippyCard"

const Page2 = () => {
  const [isFlipped1, setIsFlipped1] = useState(false)
  const [isFlipped2, setIsFlipped2] = useState(false)
 

  const data = useStaticQuery(graphql`
    query {
      Front: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "FPage2Front" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Back: allFile(
        sort: { order: ASC, fields: name }

        filter: { sourceInstanceName: { eq: "FPage2Back" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <HomeLayout>
         <Img
              fluid={data.Front.nodes[0].childImageSharp.fluid}
              alt="Front image"
            />
             <Img
              fluid={data.Front.nodes[1].childImageSharp.fluid}
              alt="Front image"
            />
      <div
        onClick={() => {
          if (!isFlipped1) {
            setIsFlipped2(false)
            setIsFlipped1(true)
          } else {
            setIsFlipped1(false)
          }
        }}
      >
        <Flippy flipDirection="horizontal" isFlipped={isFlipped1}>
          <FrontSide>
            <Img
              fluid={data.Front.nodes[2].childImageSharp.fluid}
              alt="Front image"
            />
          </FrontSide>
          <BackSide>
            <Img
              fluid={data.Back.nodes[0].childImageSharp.fluid}
              alt="Back image"
              fadeIn={false}
            />
          </BackSide>
        </Flippy>
      </div>
      <div
        onClick={() => {
          if (!isFlipped2) {
            setIsFlipped1(false)
            setIsFlipped2(true)
          } else {
            setIsFlipped2(false)
          }
        }}
      >
        <Flippy flipDirection="horizontal" isFlipped={isFlipped2}>
          <FrontSide>
            <Img
              fluid={data.Front.nodes[3].childImageSharp.fluid}
              alt="Front image"
            />
          </FrontSide>
          <BackSide>
            <Img
              fluid={data.Back.nodes[1].childImageSharp.fluid}
              alt="Back image"
              fadeIn={false}
            />
          </BackSide>
        </Flippy>
      </div>
      
      
      <Img fluid={data.Front.nodes[4].childImageSharp.fluid}/>
   
    </HomeLayout>
  )
}

export default Page2
