import React from "react"
import Layout from "../../components/Layout/Layout"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Page1 from "../../components/Finearts/Page1"
import Page2 from "../../components/Finearts/Page2"

const Finearts = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    dots: true,
  }
  
  return (
    <Layout>
      <Slider {...settings}>
       <Page1/>
       <Page2/>
      </Slider>
    </Layout>
  )
}

export default Finearts
